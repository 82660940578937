import React, { useContext } from 'react'

// @ts-ignore
import Helmet from 'react-helmet'

import { t } from '../components/Translator'

// @ts-ignore
import content from '../../content/virtual_tour.yaml'
import Header from '../components/Header'
import {AppContext} from "../components/ContextProvider";

const VirtualTourPage: React.FC = () => {

    const context = useContext(AppContext)

    // (!) undefined checks to enable 'gatsby build'
    let locale = 'nl'
    if(!context || !context.locale) {
        // console.error(`# VirtualTourPage ${!context ? 'context' : 'context locale'} undefined`)
    }
    else {
        locale = context.locale.lang.toLowerCase()
    }

    const url = `https://ssk-virtual-tour.netlify.app?locale=${locale}`

    console.log('# VirtualTourPage iFrame url = '+url)

    const head = content.head

    return (
        <main>
            <Helmet>
                <title>{t(head.title)}</title>
                <meta name="description" content={t(head.description)}/>
                <meta name="keywords" content={t(head.keywords)}/>
            </Helmet>
            {/*
                (!) Helmet solution to overwrite Gatsby html.tsx styling <body className="body" {...props.bodyAttributes}>
                // Helmet example: https://github.com/gatsbyjs/gatsby/issues/21690
                // was not needed here eventually
                // Inside your component: options for solution
                <Helmet bodyAttributes={{ class: 'new-class-for-body'}}/>
                <Helmet bodyAttributes={{style: 'overflow-y: hidden; margin-bottom: 100px;'}}/>
                <Helmet bodyAttributes={{class: 'vt-body', style: 'overflow-y: hidden'}}/>
                <Helmet><body className="new-class-for-body" /></Helmet>
            */}
            <Header/>
            <div className="vt-iframe-container">
                <iframe src={url} height="100%" width="100%" title={t(head.title)}/>
            </div>
        </main>
    )
}

export default VirtualTourPage